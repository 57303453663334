import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState, ReactFragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { AppContext, url } from "../utils";
import {
  ToastNotify,
  handleKeyDown,
  handleWheel,
} from "../components/SmallComponents/AppComponents";
import {
  nftPayableWriteFunction,
  nftReadFunction,
} from "../ConnectivityAssets/hooks";
import { nftAddress } from "../ConnectivityAssets/environment";
import TimerCountDown from "../Timer";
import Loading from "../components/SmallComponents/loading";
import { formatUnits, parseUnits } from "viem";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { Padding } from "@mui/icons-material";

export default function MintPage({ setCallFunction }) {
  const navigate = useNavigate();
  const { account } = useContext(AppContext);
  let [counter, setcounter] = useState(10);
  const [fullSupply, setFullSupply] = useState(0);
  const [checkSupply, setCheckSupply] = useState(0);
  const [loading, setloading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  // const getRandomNumber = (num) => {
  //   let randomNumber;

  //   do {
  //     randomNumber = Math.ceil(Math.random() * 5);
  //   } while (randomNumber === +num);

  //   return randomNumber;
  // };

  const mintHandler = async (index) => {
    if (!account) {
      setAlertState({
        open: true,
        message: `Please connect your wallet`,
        severity: "error",
      });
    } else if (counter > 100) {
      setAlertState({
        open: true,
        message: `Thank you for staying PEPish! BUT, you can only mint 100 Embryos per wallet!`,
        severity: "error",
      });
    } else {
      try {
        setloading(true);
        let mintCost = 0;
        mintCost = +mintCost * +counter;
        // const approved = await nftReadFunction("isApprovedForAll", [
        //   account,
        //   nftAddress,
        // ]);
        // if (!approved) {
        //   await nftPayableWriteFunction("setApprovalForAll", [
        //     nftAddress,
        //     true,
        //   ]);
        // }
        let tokenID = await nftReadFunction("totalSupply");
        let price = await nftReadFunction("currentPrice");
        price = formatUnits(price.toString(), 18).toString();
        price = (+price * +counter).toFixed(8);
        tokenID = tokenID.toString();
        tokenID = +tokenID;
        await nftPayableWriteFunction(
          "freeMint",
          [+counter]
          // parseUnits(price.toString(), 18).toString()
        );
        await axios.post(`${url}/add-nft-record`, {
          counter,
          totalSupply: +tokenID,
        });
        // for (let index = 0; index < +counter; index++) {
        //   const { data: chkSupply } = await axios.get(
        //     `${url}/check-record-supply`
        //   );
        //   const newArray = [...chkSupply];
        //   let randomOne = Math.ceil(Math.random() * newArray?.length);
        //   const random = newArray[randomOne - 1];
        //   let obj = {
        //     id: +tokenID,
        //     name:
        //       +random === 1
        //         ? "GREEN"
        //         : +random === 2
        //         ? "GOLD"
        //         : +random === 3
        //         ? "PURPLE"
        //         : +random === 4
        //         ? "MYSTIC"
        //         : "BLUE",
        //     image: `https://gateway.pinata.cloud/ipfs/QmUnjSSJnNKsmeKuByachJm7dZb43bwyvTE1jtWVPzqBjr/${+random}.png`,
        //     animation_url: `https://gateway.pinata.cloud/ipfs/QmU9DSY8JHtM8QRcpP2HLdJCnLWxe9qteQPiGLux4KU3t7/${+random}.mp4`,
        //     description: "",
        //   };
        //   const objAdded = await JSON.stringify(obj);
        //   const { data: updateMintedRecord } = await axios.put(
        //     `${url}/update-minted-counter`,
        //     {
        //       nftID: +random,
        //     }
        //   );
        //   const { data } = await axios.post(`${url}/nft-data`, {
        //     tokenID: +tokenID,
        //     nftMetadata: objAdded,
        //   });
        //   tokenID++;
        // }
        setAlertState({
          open: true,
          message: `Success! Transaction Confirmed.`,
          severity: "success",
        });
        setloading(false);
        let balance = await nftReadFunction("balanceOf", [account]);
        // console.log(+balance.toString(), "+balance.toString()");
        setCallFunction(true);
        if (+balance.toString() > 0) {
          navigate("/embryos");
        }
      } catch (error) {
        setloading(false);
        setAlertState({
          open: true,
          message: error?.shortMessage,
          severity: "error",
        });
      }
    }
  };
  useEffect(() => {
    (async () => {
      try {
        let totalSupplayCheck = await nftReadFunction("totalSupply");
        setCheckSupply(+totalSupplayCheck.toString());
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <Box pb={10}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Container maxWidth="md">
        <Grid
          container
          spacing={5}
          justifyContent="center"
          flexDirection={{ xs: "column-reverse", sm: "row" }}
        >
          <Grid item xs={12} sm={6}>
            <Box>
              <Box fontSize="36px" fontWeight={700} color="#ffffff" my={2}>
                PEPE Embryo
              </Box>
              <Box display="flex">
                <Box
                  fontSize="22px"
                  fontWeight={700}
                  color="#ffffff"
                  my={2}
                  display="flex"
                  py="5px"
                >
                  Phase 3:
                </Box>
                <Box
                  fontSize="22px"
                  fontWeight={700}
                  color="#ffffff"
                  my={2}
                  sx={{
                    borderRadius: "50px",
                    backgroundColor: "rgb(50, 23, 54)",
                    px: "10px",
                    py: "5px",
                    ml: "10px",
                  }}
                >
                  Free Mint
                </Box>
              </Box>
              <Box
                lineHeight={1.5}
                fontSize="22px"
                fontWeight={500}
                color="#ffffff"
                letterSpacing={1}
                my={2}
              >
                This is your opportunity to get one of the next generation WEB3
                assets at a price of $0 - FREE MINT - and be part of a growing
                cultural experience that is about to explode worldwide.
                <br />
                <br />
                EVERYONE IS $PEPE, and Life Of PEPE is here to help expand the
                culture through a growing, educational, gamified experience.
                Starting with our PEPE Embryos!
                <br />
              </Box>
              <Box>
                <Box
                  component={"ul"}
                  mt={2}
                  color="#ffffff"
                  fontSize="24px"
                  fontWeight={500}
                  style={{
                    paddingLeft: "18px",
                  }}
                >
                  {[
                    // "69,696 PEPE Embroys",
                    "Final Supply: 20,000 PEPE Embryo NFTs will survive, grow & thrive.",
                    "Max per wallet: 100",
                    // "Price: $4 (Phase 3 will increase to $5)",
                    "Price: $0 (* only gas)",
                  ].map((dt, i) => (
                    <React.Fragment key={dt}>
                      {/* {i === 0 ? (
                        <li style={{ marginTop: "15px" }}>
                          {dt}{" "}
                          <span style={{ fontWeight: "700" }}>
                            (Only +-20,000 PEPE NFTs will survive, grow &
                            thrive.)
                          </span>
                        </li>
                      ) : ( */}
                      <li style={{ marginTop: "20px" }}>{dt}</li>
                      {/* )} */}
                    </React.Fragment>
                  ))}
                </Box>
                {/* <Box
                  display="flex"
                  my={2}
                  alignItems="center"
                  color="#ffffff"
                  fontSize="24px"
                  fontWeight={500}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CircleIcon
                      sx={{ color: "rgb(50, 23, 54)", fontSize: "10px" }}
                    />
                    <Box ml="16px" display="flex" textAlign="center">
                      <TimerCountDown time={1689361200} />
                      <span style={{ marginTop: "2px", marginLeft: "2px" }}>
                        Minutes Remaining
                      </span>
                    </Box>
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                // background:
                //   "linear-gradient(18.12deg, rgb(50, 23, 54) 1.78%, rgb(50, 23, 54) 131.95%)",
                // cursor: "pointer",
                borderRadius: "10px",
              }}
            >
              {/* <img
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
                src={img}
                width="100%"
                height="412px"
                alt=""
              /> */}
              <Box p={0.5}>
                <video
                  autoPlay
                  crossOrigin="anonymous"
                  loop
                  muted
                  playsInline
                  preload="auto"
                  role="presentation"
                  tabIndex="-1"
                  style={{
                    //   borderTopLeftRadius: "10px",
                    //   borderTopRightRadius: "10px",
                    borderRadius: "10px",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <source src="/video.mp4" />
                </video>
              </Box>
              {/* <Box
                fontSize="30px"
                fontWeight={700}
                color="#ffffff"
                my={2}
                textAlign="center"
              >
                {checkSupply} /{" "}
                <span style={{ color: "#747875", fontWeight: "900" }}>
                  69,696 Minted
                </span>
              </Box> */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                px={4}
                mt={-0.5}
              >
                <Box display="flex" alignItems="center" ml={2}>
                  <Box
                    mr={1}
                    onClick={() => {
                      if (counter > 1) {
                        setcounter(--counter);
                      }
                    }}
                    sx={{
                      borderRadius: "0px",
                      cursor: "pointer",
                      px: 0.8,
                      py: 1.4,
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    <RemoveIcon
                      style={{
                        color: "#ffffff",
                        fontSize: "30px",
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "70px" }}>
                    <TextField
                      sx={{
                        background: "#000000",
                        borderRadius: "12px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px",
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },

                        input: {
                          fontSize: "25px",
                          fontWeight: "700",
                          fontFamily: "Agdasima",
                          textAlign: "center",
                          color: "#ffffff",
                        },
                      }}
                      name="counter"
                      type="number"
                      onKeyDown={handleKeyDown}
                      onWheel={handleWheel}
                      value={counter}
                      onChange={(e) => setcounter(e.target.value)}
                      placeholder={""}
                    />
                  </Box>
                  <Box
                    ml={1}
                    onClick={() => {
                      if (counter < 100) {
                        setcounter(++counter);
                      }
                    }}
                    sx={{
                      borderRadius: "0px",
                      cursor: "pointer",
                      px: 0.8,
                      py: 1.4,
                    }}
                  >
                    <AddIcon
                      style={{
                        color: "#ffffff",
                        fontSize: "30px",
                      }}
                    />
                  </Box>
                </Box>
                <Button
                  sx={{
                    color: "#ffffff",
                    fontSize: "25px",
                    textTransform: "capitalize",
                    borderBottomRightRadius: "10px",
                    borderBottomLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    borderTopLeftRadius: "0px",
                    fontWeight: 700,
                    fontFamily: "Agdasima",
                    px: 2,
                    width: "150px",
                    "&.Mui-disabled": {
                      color: "#979EA7",
                    },
                    "&:hover": {
                      // background: "#C2C2C6",
                    },
                  }}
                  onClick={() => mintHandler()}
                >
                  Mint
                </Button>
              </Box>
              {/* <Box
                fontSize="18px"
                fontWeight={400}
                color="#ffffff"
                textAlign="center"
              >
                Max 10 per wallet in this phase
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
