import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { img } from "../components/SmallComponents/Images";
import { tokenReadFunction } from "../ConnectivityAssets/hooks";
import { formatUnits } from "viem";
import { useAccount } from "wagmi";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import { Link } from "react-router-dom";
import { nftAddress } from "../ConnectivityAssets/environment";
import axios from "axios";
import { url } from "../utils";
import Loading from "../components/SmallComponents/loading";

function HeroSection() {
  const { address } = useAccount();
  const [balanceOf, setbalanceOf] = useState(0);
  const [balanceToShow, setbalanceToShow] = useState("");
  const [userNftsArray, setuserNftsArray] = useState([]);
  const [callFunction, setCallFunction] = useState(true);
  const [loading, setloading] = useState(false);

  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        // let balance = await tokenReadFunction("balanceOf", [address]);
        // balance = +formatUnits(balance.toString(), 18);
        // setbalanceOf(balance);
        // console.log(balance, "balance");
        // setbalanceToShow(toLocalFormat(balance));
        // setCallFunction(false);
        if (address) {
          const {
            data: { nftImages },
          } = await axios.get(`${url}/get-user-balances/${address}`);
          setuserNftsArray(nftImages);
          // const {
          //   data: { ownedNfts },
          // } = await axios.get(
          //   `https://eth-sepolia.g.alchemy.com/nft/v2/VgdhN2jMZASmL7G1HHU9_KH7hvaaiulS/getNFTs?owner=${address}&contractAddresses\[\]=${nftAddress}&withMetadata=true&pageSize=100`
          // );
          // console.log(ownedNfts, "ownedNfts");
          // if (ownedNfts?.length > 0) {
          //   const newArray = [];
          //   ownedNfts?.map(({ id }) => {
          //     console.log(id, "id");
          //     newArray.push(id.tokenId);
          //   });
          //   setuserNftsArray(newArray);
          // }
          setCallFunction(false);
          setloading(false);
        }
      } catch (error) {
        setCallFunction(false);
        setloading(false);
        console.log(error);
      }
    })();
  }, [callFunction, address]);
  return (
    <Box pb={10}>
      <Loading loading={loading} />
      <Container maxWidth="lg">
        <Grid container spacing={2} my={3}>
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              // alignItems="center"
              fontSize="35px"
              fontWeight={700}
              color="#ffffff"
              // mt={5}
            >
              Your Embryos
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              fontSize="20px"
              fontWeight={500}
              color="#ffffff"
              textAlign={{ xs: "center", sm: "left" }}
            >
              You PEPE Embryos are in need of nurturing to grow into fully-grown
              PEPEs! To ensure maximum survival, please check-in with each
              Embryo daily, this will not only give you a higher change of
              survival BUT will also speed up your next stage reveal.
            </Box>
          </Grid>
        </Grid>
        <Grid p={3} container spacing={5}>
          {userNftsArray.length > 0 ? (
            userNftsArray.map(({ nftVideo, tokenId, nftName }, index) => (
              <Grid item xs={12} sm={3} key={index}>
                <Link
                  to={`/detail/${+tokenId}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box p={1} borderRadius="10px">
                    <video
                      autoPlay
                      crossOrigin="anonymous"
                      loop
                      muted
                      playsInline
                      preload="auto"
                      role="presentation"
                      tabIndex="-1"
                      style={{
                        borderRadius: "10px",
                        height: "100%",
                        width: "100%",
                      }}
                      src={nftVideo}
                    />
                    {/* <source src={nftVideo} />
                    </video> */}
                    {/* <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#ffffff",
                        mt: 0.5,
                        textAlign: "center",
                        fontFamily: "Agdasima",
                        letterSpacing: 1,
                      }}
                    >
                      Embryo #{+tokenId}
                    </Typography> */}
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "#ffffff",
                        mt: 0.5,
                        textAlign: "center",
                        fontFamily: "Agdasima",
                        letterSpacing: 1,
                      }}
                    >
                      {nftName}
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            ))
          ) : loading ? (
            loading
          ) : (
            <Box
              mt={5}
              sx={{
                fontSize: "30px",
                fontWeight: "700",
                color: "#ffffff",
                textAlign: "center",
                fontFamily: "Agdasima",
                letterSpacing: 1,
                width: "100%",
              }}
            >
              NFT's Not Found
            </Box>
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default HeroSection;
