export const tokenAddress = "0x6982508145454Ce325dDbE47a25d4ec3d2311933";
// export const nftAddress = "0x9b34f2a46A4F1b901b1Ba0dC0e147843fE272fC6";
// export const registryAddress = "0xED84B86C594B62bdf9b57a12E4C208aDC7Bd5815";
// export const accountAddress = "0x66374118eF31b7240412E3f1534bcC30E52EA098";

// export const nftAddress = "0x2BdFDe4d649a0b8763E212ED37da6D9A4080e9Ae";
// export const accountAddress = "0x9D0212324c0736357e8F5a01e79FB4b34874eD34";
// export const registryAddress = "0x16bee3ccfBf66720E2357D07324Cf57239858dAD";

export const nftAddress = "0xa87ea7C8745980490BcDcFf97FE7328535098CD1";
export const accountAddress = "0x5AE5F4d4982EdE2C820d2a9827CCb97fED6cEF71";
export const registryAddress = "0x76D49B573eF641faF2Ba5B9f24c74777Ab140841";
