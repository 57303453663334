import tokenAbi from "./tokenAbi.json";
import nftAbi from "./nftAbi.json";
import registryAbi from "./registryAbi.json";
import { nftAddress, tokenAddress, registryAddress } from "./environment";

import { readContract, writeContract } from "wagmi/actions";
import { waitForTransaction } from "@wagmi/core";

export const tokenReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: tokenAddress,
    abi: tokenAbi,
    functionName,
    args,
  });
  return data;
};
export const nftReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: nftAddress,
    abi: nftAbi,
    functionName,
    args,
  });
  return data;
};
export const registryReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: registryAddress,
    abi: registryAbi,
    functionName,
    args,
  });
  return data;
};
export const nftPayableWriteFunction = async (functionName, args, value) => {
  const { hash } = await writeContract({
    address: nftAddress,
    abi: nftAbi,
    functionName,
    args,
    value,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};
