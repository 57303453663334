import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  InputBase,
  LinearProgress,
  Slide,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import accountAbi from "../ConnectivityAssets/accountAbi.json";
import nftAbi from "../ConnectivityAssets/nftAbi.json";
import { fetchBalance } from "@wagmi/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  nftReadFunction,
  registryReadFunction,
} from "../ConnectivityAssets/hooks";
import { useParams } from "react-router-dom";
import { nftAddress } from "../ConnectivityAssets/environment";
import axios from "axios";
import { AppContext, url } from "../utils";
import Loading from "../components/SmallComponents/loading";
import {
  StyledButton,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  encodeFunctionData,
  formatUnits,
  parseEther,
  parseGwei,
  parseUnits,
  serializeTransaction,
} from "viem";
import { writeContract } from "wagmi/actions";
import { waitForTransaction } from "@wagmi/core";
import { signMessage } from "wagmi/actions";
import dayjs from "dayjs";
// import Web3 from "web3";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const TextInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    height: "30px",
    fontSize: "15px",
    fontFamily: "Agdasima",
    fontWeight: "700",
    textAlign: "left",
    color: "#000000",
    backgroundColor: "transparent",
    paddingLeft: "15px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});
export default function DetailPage() {
  const matches = useMediaQuery("(max-width:600px)");
  const { account } = useContext(AppContext);
  const { id } = useParams();
  const [balanceETHRegistry, setbalanceETHRegistry] = useState("");
  const [nftVideo, setnftVideo] = useState("");
  const [walletRegistry, setwalletRegistry] = useState("");
  const [registryWalletNftsArray, setregistryWalletNftsArray] = useState([]);
  const [registryWalletBal, setregistryWalletBal] = useState([]);
  const [callFunction, setCallFunction] = useState(true);
  const [updateFunction, setUpdatFuction] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [enteredAmount, setenteredAmount] = useState("");
  const [enteredAddress, setenteredAddress] = useState("");
  const [sendingTokenAddress, setsendingTokenAddress] = useState("");
  const [sendingtokenBalance, setsendingtokenBalance] = useState(0);
  const [sendingTokenDec, setsendingTokenDec] = useState(0);
  const [userStatus, setUserStatus] = useState(false);
  const [sendingETHBalance, setsendingETHBalance] = useState(0);
  const [sendingNFTId, setsendingNFTId] = useState("");
  const [sendingNFTAddress, setsendingNFTAddress] = useState("");
  const [progressBarData, setProgressBarData] = useState(0);
  const [userLoginTime, setUserLoginTime] = useState("");
  const [updateProgress, setUpdatProgress] = useState(false);
  const [loading, setloading] = useState(false);
  const [viewer, setviewer] = useState(true);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  // const web3 = new Web3(
  //   Web3.givenProvider
  //     ? Web3.givenProvider
  //     : "https://eth-sepolia.g.alchemy.com/v2/demo"
  // );

  let dataAbiToken = [
    {
      inputs: [
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "transfer",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];
  let dataAbiNFT = [
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];
  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        let implementation = await nftReadFunction("accountImplementation");
        let chainID = await nftReadFunction("getChainID");
        let getAddress = await registryReadFunction("account", [
          implementation,
          chainID,
          nftAddress,
          id,
          490,
        ]);
        setwalletRegistry(getAddress);
        // console.log("Aya K NI_________");
        const {
          data: { nftImages },
        } = await axios.get(`${url}/get-registry-nfts/${getAddress}`);
        setregistryWalletNftsArray(nftImages);
        const { data } = await axios.get(
          `${url}/get-registry-tokens/${getAddress}`
        );
        setregistryWalletBal(data);
        const walletBalance = await fetchBalance({
          address: getAddress,
        });
        let ethBal = +formatUnits(walletBalance?.value?.toString(), 18);
        setbalanceETHRegistry(ethBal);
        if (id) {
          const { data } = await axios.get(`${url}/get-single-nft/${+id}`);
          setnftVideo(data);
        }
        setCallFunction(false);
        setUpdatFuction(false);
        setloading(false);
      } catch (error) {
        setloading(false);
        console.log(error);
      }
    })();
  }, [id, updateFunction]);

  let accountPayableWriteFunction = async (functionName, args, value) => {
    // console.log(walletRegistry, "walletRegistry");
    const { hash } = await writeContract({
      address: walletRegistry,
      abi: accountAbi,
      functionName,
      args,
      value,
    });
    const receipt = await waitForTransaction({ hash });
    return receipt;
  };

  useEffect(() => {
    if (account) {
      (async () => {
        try {
          const { data } = await axios.get(
            `${url}/get-check-in-point/${+id}/${account.toLowerCase()}`
          );
          const progressData = (data?.checkInCounter / data?.totalChekIn) * 100;
          const lastLoginDate = dayjs.unix(data?.loginTime);
          const todaysDate = new Date();
          const hourDiff = dayjs(todaysDate).diff(
            dayjs(lastLoginDate),
            "hours"
          );
          setProgressBarData(progressData);
          setUserStatus(data?.userStatus);
          setUserLoginTime(hourDiff);
          setUpdatProgress(false);
        } catch (error) {
          setUpdatProgress(false);
          console.log(error);
        }
      })();
    }
  }, [account, updateProgress]);

  const signMessg = async () => {
    try {
      if (!account) {
        return setAlertState({
          open: true,
          message: `Please connect your wallet`,
          severity: "error",
        });
      }
      const ownerAddress = await nftReadFunction("ownerOf", [+id]);
      if (ownerAddress?.toLowerCase() !== account?.toLowerCase()) {
        return setAlertState({
          open: true,
          message: `You are not owner of this NFT`,
          severity: "error",
        });
      }

      await signMessage({
        account,
        message:
          "Your Embryo needs to be nurtured and checked on regularly to ensure survival! Thank you for checking in, and being a good PEPish parent!",
      });
      // setAlertState({
      //   open: true,
      //   message: `Success! Sign message`,
      //   severity: "success",
      // });
      await axios.post(`${url}/add-check-in-point`, {
        walletAddress: account?.toLowerCase(),
        tokenID: +id,
      });
      setUpdatProgress(true);
    } catch (error) {
      setUpdatProgress(false);
      console.log(error);
    }
  };
  const transferHandler = async () => {
    if (!account) {
      setAlertState({
        open: true,
        message: `Please connect your wallet`,
        severity: "error",
      });
    } else {
      try {
        setloading(true);
        // console.log(+sendingETHBalance, "sendingETHBalance");
        // console.log(+sendingtokenBalance, "sendingtokenBalance");
        if (+sendingETHBalance > 0) {
          if (enteredAmount <= 0) {
            setAlertState({
              open: true,
              message: `Please enter amount`,
              severity: "error",
            });
          } else if (+enteredAmount > +sendingETHBalance) {
            setAlertState({
              open: true,
              message: `Don't have enough balance`,
              severity: "error",
            });
          } else if (+enteredAddress.length !== 42 || !enteredAddress) {
            setAlertState({
              open: true,
              message: `Please enter vaild wallet address`,
              severity: "error",
            });
          } else {
            console.log("ETH_CALLED");
            setloading(true);
            // const amountToSend = parseUnits(enteredAmount.toString());
            // var send = web3.eth.sendTransaction({
            //   from: walletRegistry,
            //   to: enteredAddress,
            //   value: amountToSend.toString(),
            // });
            // setAlertState({
            //   open: true,
            //   message: `Success! Transaction Confirmed.`,
            //   severity: "success",
            // });
            // ethers.utils.serializeTransaction({
            //   to: "0xa238b6008Bc2FBd9E386A5d4784511980cE504Cd",
            //   value: ethers.utils.parseEther("1"),
            //   gasLimit: "21000",
            //   maxPriorityFeePerGas: ethers.utils.parseUnits("5", "gwei"),
            //   maxFeePerGas: ethers.utils.parseUnits("20", "gwei"),
            //   nonce: 1,
            //   type: 2,
            //   chainId: 3,
            // });
            const sendingAmount = parseUnits(
              enteredAmount.toString(),
              18
            ).toString();
            await accountPayableWriteFunction(
              "transferETH",
              [enteredAddress, sendingAmount],
              0
            );
            setAlertState({
              open: true,
              message: `Success! Transaction Confirmed.`,
              severity: "success",
            });
            setloading(false);
            setUpdatFuction(true);
            setenteredAddress("");
            setenteredAmount("");
            setopenDialog(false);
          }
        } else {
          if (+enteredAmount > +sendingtokenBalance) {
            setAlertState({
              open: true,
              message: `Don't have enough balance`,
              severity: "error",
            });
          } else if (+enteredAddress.length !== 42 || !enteredAddress) {
            setAlertState({
              open: true,
              message: `Please enter vaild wallet address`,
              severity: "error",
            });
          } else {
            // console.log(sendingtokenBalance, +sendingTokenDec, "tokenBalance");
            let tokens = parseUnits(
              enteredAmount.toString(),
              +sendingTokenDec
            ).toString();
            const encodedData = encodeFunctionData({
              abi: dataAbiToken,
              functionName: "transfer",
              args: [enteredAddress, tokens],
            });
            // console.log(enteredAddress, tokens, "Arguments");
            // console.log(encodedData, "EncodedData");
            // console.log(sendingTokenAddress, 0, encodedData, 0, "executeCall");
            await accountPayableWriteFunction(
              "executeCall",
              [sendingTokenAddress, 0, encodedData],
              0
            );
            setAlertState({
              open: true,
              message: `Success! Transaction Confirmed.`,
              severity: "success",
            });
          }
        }
        setloading(false);
        setUpdatFuction(true);
      } catch (error) {
        console.log(error);
        setloading(false);
        setUpdatFuction(false);
        setAlertState({
          open: true,
          message: error?.shortMessage,
          severity: "error",
        });
      }
    }
  };
  const nftTransferHandler = async () => {
    if (!account) {
      setAlertState({
        open: true,
        message: `Please connect your wallet`,
        severity: "error",
      });
    } else {
      try {
        setloading(true);
        const encodedData = encodeFunctionData({
          abi: dataAbiNFT,
          functionName: "transferFrom",
          args: [walletRegistry, enteredAddress, sendingNFTId],
        });
        // console.log(walletRegistry, enteredAddress, sendingNFTId, "Arguments");
        // console.log(encodedData, "EncodedData");
        // console.log(walletRegistry, 0, encodedData, 0, "executeCall");
        await accountPayableWriteFunction(
          "executeCall",
          [sendingNFTAddress, 0, encodedData],
          0
        );
        setloading(false);
        setUpdatFuction(true);
        setAlertState({
          open: true,
          message: `Success! Transaction Confirmed.`,
          severity: "success",
        });
      } catch (error) {
        console.log(error);
        setloading(false);
        setUpdatFuction(false);
        setAlertState({
          open: true,
          message: error?.shortMessage,
          severity: "error",
        });
      }
    }
  };

  return (
    <Box pb={10}>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        sx={{
          zIndex: 1,
          "& .MuiPaper-root": {
            backgroundColor: "#ffffff",
            width: matches ? "100%" : "25%",
            borderRadius: "25px",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "25px",
            height: "100%",
          }}
        >
          <Box
            py={1}
            pl={3}
            pr={1}
            sx={{
              background: "rgb(50, 23, 54)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              textAlign="center"
              fontSize="20px"
              fontWeight="700"
              fontFamily="Agdasima"
              color="#ffffff"
            >
              Transfer
            </Box>
            <CloseIcon
              onClick={() => {
                setenteredAmount("");
                setenteredAddress("");
                setsendingETHBalance(0);
                setopenDialog(!openDialog);
              }}
              style={{
                cursor: "pointer",
                color: "#fff",
              }}
            />
          </Box>

          <Box py={3} px={2}>
            {viewer ? (
              <Box
                sx={{
                  border: "1px solid #212529",
                  borderRadius: "8px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextInput
                  fullWidth
                  placeholder="Enter amount"
                  type="number"
                  value={enteredAmount}
                  onChange={(e) => setenteredAmount(e.target.value)}
                />
              </Box>
            ) : null}
            <Box mt={viewer ? 2 : 0}>
              <Box
                sx={{
                  border: "1px solid #212529",
                  borderRadius: "8px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextInput
                  fullWidth
                  placeholder="Enter Address"
                  type="text"
                  value={enteredAddress}
                  onChange={(e) => setenteredAddress(e.target.value)}
                />
              </Box>
            </Box>
            <Box mt={2} />
            <StyledButton
              mt={2}
              width="100%"
              onClick={() => {
                viewer ? transferHandler() : nftTransferHandler();
              }}
            >
              Transfer
            </StyledButton>
          </Box>
        </Box>
      </Dialog>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Box p={1} borderRadius="10px">
              <video
                autoPlay
                crossOrigin="anonymous"
                loop
                muted
                playsInline
                preload="auto"
                role="presentation"
                tabIndex="-1"
                style={{
                  borderRadius: "10px",
                  height: "100%",
                  width: "100%",
                }}
                src={nftVideo}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <Grid container px={{ xs: 1, sm: 0 }}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="18px"
                    fontWeight="400"
                    color="#fff"
                    bgcolor="#12db1c"
                    width={matches ? "100%" : "150px"}
                    borderRadius="30px"
                    fontFamily="Agdasima"
                    letterSpacing={2}
                    py={1}
                    mt={matches ? 1 : 0}
                    sx={{ cursor: userStatus ? "no-drop" : "pointer" }}
                    onClick={userStatus ? null : signMessg}
                  >
                    Check In
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box my={{ xs: 2, sm: 0 }}>
                    <Typography
                      fontSize="18px"
                      fontWeight="700"
                      color="#fff"
                      fontFamily="Agdasima"
                      mr={1}
                    >
                      {userStatus
                        ? `You checked in last ${userLoginTime} ${
                            +userLoginTime === 1 ? "hour" : "hours"
                          }  ago`
                        : "You have not checked in. It's time to start!"}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={progressBarData}
                      sx={{
                        height: 10,
                        backgroundColor: "rgb(75, 32, 82)",
                        borderRadius: 5,
                        ".MuiLinearProgress-barColorPrimary": {
                          backgroundColor: "#12db1c",
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              bgcolor="rgb(50, 23, 54)"
              borderRadius="10px"
              sx={{ padding: "20px 26px" }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={matches ? "column" : "row"}
              >
                <a
                  href={`https://etherscan.io/address/${walletRegistry}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    width: matches ? "100%" : "150px",
                  }}
                >
                  <Typography
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="18px"
                    fontWeight="400"
                    color="#fff"
                    width={matches ? "100%" : "150px"}
                    bgcolor="rgb(40, 20, 30)"
                    boxShadow="4px 4px 8px rgb(75, 32, 82)"
                    borderRadius="30px"
                    fontFamily="Agdasima"
                    letterSpacing={2}
                    py={1}
                    sx={{ cursor: "pointer" }}
                  >
                    {walletRegistry?.slice(0, 6) + "..."}
                    <LaunchIcon style={{ fontSize: "20px" }} />
                  </Typography>
                </a>
                {/* <Box display="flex" alignItems="center"> */}
                <CopyToClipboard
                  text={walletRegistry}
                  onCopy={() => {
                    setAlertState({
                      open: true,
                      message: `Address Copied.`,
                      severity: "success",
                    });
                  }}
                >
                  <Typography
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="18px"
                    fontWeight="700"
                    color="rgb(50, 23, 54)"
                    bgcolor="#ffffff"
                    boxShadow="4px 4px 8px rgb(75, 32, 82)"
                    width={matches ? "100%" : "150px"}
                    borderRadius="30px"
                    fontFamily="Agdasima"
                    letterSpacing={2}
                    py={1}
                    mt={matches ? 1 : 0}
                    sx={{ cursor: "pointer" }}
                  >
                    Use Wallet
                  </Typography>
                </CopyToClipboard>
                {/* <Typography
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="18px"
                  fontWeight="400"
                  color="#fff"
                  bgcolor="rgb(40, 20, 30)"
                  boxShadow="4px 4px 8px rgb(75, 32, 82)"
                  width={matches ? "100%" : "150px"}
                  borderRadius="30px"
                  fontFamily="Agdasima"
                  letterSpacing={2}
                  py={1}
                  mt={matches ? 1 : 0}
                  sx={{ cursor: "pointer" }}
                >
                  Check In
                </Typography> */}
                {/* </Box> */}
              </Box>
              <Box
                mt={2}
                display="flex"
                alignItems="center"
                flexDirection={matches ? "column" : "row"}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="1.225rem"
                  fontWeight="400"
                  color="#fff"
                  bgcolor={viewer ? "rgb(75, 32, 82)" : "rgb(40, 20, 30)"}
                  // bgcolor="#fff"
                  boxShadow={
                    viewer
                      ? "4px 4px 8px rgb(40, 20, 30)"
                      : "4px 4px 8px rgb(75, 32, 82)"
                  }
                  width={matches ? "100%" : "150px"}
                  borderRadius="10px"
                  fontFamily="Agdasima"
                  letterSpacing={2}
                  py={1}
                  mt={2}
                  onClick={() => setviewer(true)}
                  sx={{ cursor: "pointer" }}
                >
                  {/* <span style={{ marginRight: "5px" }}>💰</span>  */}
                  Assets
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="1.225rem"
                  fontWeight="400"
                  color="#fff"
                  // bgcolor="rgb(40, 20, 30)"
                  // boxShadow="4px 4px 8px rgb(75, 32, 82)"
                  bgcolor={viewer ? "rgb(40, 20, 30)" : "rgb(75, 32, 82)"}
                  boxShadow={
                    viewer
                      ? "4px 4px 8px rgb(75, 32, 82)"
                      : "4px 4px 8px rgb(40, 20, 30)"
                  }
                  width={matches ? "100%" : "150px"}
                  borderRadius="10px"
                  fontFamily="Agdasima"
                  letterSpacing={2}
                  py={1}
                  mt={matches ? 1 : 2}
                  ml={matches ? 0 : 2}
                  onClick={() => setviewer(false)}
                  sx={{ cursor: "pointer" }}
                >
                  {/* <span style={{ marginRight: "5px" }}>🖼️</span>  */}
                  Collectibles
                </Box>
              </Box>
              {viewer ? (
                <>
                  {balanceETHRegistry > 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box
                        letterSpacing={1.3}
                        fontSize="20px"
                        fontWeight={600}
                        color="#ffffff"
                        mt={3}
                      >
                        ETH: {parseFloat(balanceETHRegistry).toFixed(3)}
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontSize="1.225rem"
                        fontWeight="400"
                        color="#fff"
                        bgcolor="rgb(40, 20, 30)"
                        boxShadow="4px 4px 8px rgb(75, 32, 82)"
                        width="100px"
                        borderRadius="50px"
                        fontFamily="Agdasima"
                        letterSpacing={2}
                        py={1}
                        onClick={() => {
                          setsendingETHBalance(balanceETHRegistry);
                          setopenDialog(true);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        Transfer
                      </Box>
                    </Box>
                  )}

                  {+registryWalletBal.length > 0 ? (
                    registryWalletBal.map(
                      (
                        { tokenBalance, tokenName, tokenAddress, tokenDec },
                        index
                      ) => (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          key={index}
                        >
                          <Box
                            letterSpacing={1.3}
                            fontSize="20px"
                            fontWeight={600}
                            color="#ffffff"
                            mt={+balanceETHRegistry > 0 ? 1 : 3}
                          >
                            {tokenName}: {parseFloat(tokenBalance).toFixed(3)}
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            fontSize="1.225rem"
                            fontWeight="400"
                            color="#fff"
                            bgcolor="rgb(40, 20, 30)"
                            boxShadow="4px 4px 8px rgb(75, 32, 82)"
                            width="100px"
                            borderRadius="50px"
                            fontFamily="Agdasima"
                            letterSpacing={2}
                            py={1}
                            onClick={() => {
                              setsendingtokenBalance(tokenBalance);
                              setsendingTokenAddress(tokenAddress);
                              setsendingTokenDec(tokenDec);
                              setopenDialog(true);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            Transfer
                          </Box>
                        </Box>
                      )
                    )
                  ) : loading ? (
                    loading
                  ) : (
                    <>
                      {+balanceETHRegistry > 0 ? null : (
                        <Box
                          mt={5}
                          sx={{
                            fontSize: "30px",
                            fontWeight: "700",
                            color: "#ffffff",
                            textAlign: "center",
                            fontFamily: "Agdasima",
                            letterSpacing: 1,
                            width: "100%",
                          }}
                        >
                          Nothing Found
                        </Box>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Grid mt={1} container spacing={2}>
                  {+registryWalletNftsArray.length > 0 ? (
                    registryWalletNftsArray.map(
                      ({ nftVideo, tokenId, nftAddress }, index) => (
                        <Grid item xs={12} sm={3}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <img
                              style={{ borderRadius: "10px" }}
                              src={nftVideo}
                              width="100%"
                              height="100%"
                              alt=""
                            />
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              fontSize="1.225rem"
                              fontWeight="400"
                              color="#fff"
                              bgcolor="rgb(40, 20, 30)"
                              boxShadow="4px 4px 8px rgb(75, 32, 82)"
                              width="100px"
                              borderRadius="50px"
                              fontFamily="Agdasima"
                              letterSpacing={2}
                              py={1}
                              mt={1}
                              onClick={() => {
                                setsendingNFTId(tokenId);
                                setsendingNFTAddress(nftAddress);
                                setopenDialog(true);
                              }}
                              sx={{ cursor: "pointer" }}
                            >
                              Transfer
                            </Box>
                          </Box>
                        </Grid>
                      )
                    )
                  ) : loading ? (
                    loading
                  ) : (
                    <Box
                      mt={5}
                      sx={{
                        fontSize: "30px",
                        fontWeight: "700",
                        color: "#ffffff",
                        textAlign: "center",
                        fontFamily: "Agdasima",
                        letterSpacing: 1,
                        width: "100%",
                      }}
                    >
                      Nothing Found
                    </Box>
                  )}
                </Grid>
              )}

              {/* <Box display="flex" justifyContent="center">
                <Button
                  sx={{
                    color: "#321736",
                    background: "#C2C2C6",
                    fontSize: "18px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    textTransform: "capitalize",
                    borderRadius: "0px",
                    fontWeight: 700,
                    px: 2,
                    width: "100%",
                    "&.Mui-disabled": {
                      color: "#979EA7",
                    },
                    "&:hover": {
                      background: "#C2C2C6",
                    },
                  }}
                >
                  Transfer
                </Button>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
