import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import MintPage from "./pages/MintPage";
import DetailPage from "./pages/DetailPage";
import { nftReadFunction } from "./ConnectivityAssets/hooks";
import { useAccount, useNetwork } from "wagmi";
import { useEffect, useState } from "react";
import NetworkSwitch from "./NetworkSwitch";

function App() {
  const { address } = useAccount();
  const [callFunction, setCallFunction] = useState(false);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const [nftBalance, setnftBalance] = useState(0);
  const { chain } = useNetwork();

  useEffect(() => {
    if (address) {
      (async () => {
        // const { data } = await axios.get(`${url}/pepe-nft-api/${3}`);
        // let metadata = JSON.parse(data.tokenData.nftMetadata);
        // console.log(data, "metadata");
        try {
          let balance = await nftReadFunction("balanceOf", [address]);
          setnftBalance(+balance.toString());
          setCallFunction(false);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [callFunction, address]);

  useEffect(() => {
    if (address && chain && chain?.id !== 1) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, address]);
  
  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Header nftBalance={nftBalance} />
      <Routes>
        <Route
          path="/"
          element={<MintPage setCallFunction={setCallFunction} />}
        />

        {address && +nftBalance > 0 ? (
          <Route path="/embryos" element={<HeroSection />} />
        ) : null}
        <Route path="/detail/:id" element={<DetailPage />} />
      </Routes>
    </>
  );
}

export default App;
